export const HEADER_FIELDS = 'HEADER_FIELDS';
export const MANDATORY_COLUMNS = 'MANDATORY_COLUMNS';
export const TABLE_ATTRIBUTES = 'TABLE_ATTRIBUTES';
export const CURRENT_HEADER = 'CURRENT_HEADER';
export const FILE_NAME = 'FILE_NAME';
export const PAGES = 'PAGES';
export const PAGES_COUNT = 'PAGES_COUNT';
export const DROP_DOWN_VALUE = 'DROP_DOWN_VALUE';
export const RESULT = 'RESULT';
export const FLAG_INPUT = 'FLAG_INPUT';
export const RELOAD_FLAG = 'RELOAD_FLAG';
export const HEADER_ERROR = 'HEADER_ERROR';
export const ERROR_LIST = 'ERROR_LIST';
export const DATE_LIST = 'DATE_LIST';
export const IMG_PREVIEW = 'IMG_PREVIEW';

export const PRE_IMAGE_DETIALS = 'PRE_IMAGE_DETIALS';

export const ACTIVE_PAGE = 'ACTIVE_PAGE';
export const ACTIVE_JOB = 'ACTIVE_JOB';
export const SHOW_SAVE_BUTTON = 'SHOW_SAVE_BUTTON';
export const SHOW_RESET_BUTTON = 'SHOW_RESET_BUTTON';
export const ZOOM = 'ZOOM';
export const SETWIDTH = 'SETWIDTH';
export const LOGICAL_TOKENS = 'LOGICAL_TOKENS';
export const IS_POPPER_VISIBLE = 'IS_POPPER_VISIBLE';
export const RND_FLAG = 'RND_FLAG';
export const DRAG_MOVE = 'DRAG_MOVE';
export const EDITED_HEADER_FIELDS = 'EDITED_HEADER_FIELDS';
export const GET_GRAPHS = 'GET_GRAPHS';
export const GET_EXTRACTED_DATA_SUCCESS = 'GET_EXTRACTED_DATA_SUCCESS';
export const GET_EXTRACTED_DATA_FAILED = 'GET_EXTRACTED_DATA_FAILED';
export const SHOW_DONE_BUTTON = 'SHOW_DONE_BUTTON';

export const EXTRACTION_FLAG = 'EXTRACTION_FLAG';
export const ASSETLEVEL_RESP = 'ASSETLEVEL_RESP';
export const CONTRACTLEVEL_RESP = 'CONTRACTLEVEL_RESP';
export const VALIDATION_RESP = 'VALIDATION_RESP';

export const IS_REVIWED = 'IS_REVIWED';

export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const SET_EDITABLE_SIGNATURE = 'SET_EDITABLE_SIGNATURE';